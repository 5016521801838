import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';

const Case7 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/7.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      return (
        <div className={classes.case} id="sales-analysis">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>Use Case #7. Sales analysis</h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 60}}>
            You want to make sales analysis that you get from your POS
            installations.
          </p>
          <Img fluid={img} style={{maxWidth: 850}} />
          <p style={{marginTop: 60}}>
            The description here will be added after v17 release.
          </p>
        </div>
      );
    }}
  />
);

Case7.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case7);
